<template>
  <div class="">
    <el-dialog
      title="More"
      :visible.sync="dialogFormVisible"
      :before-close="handleClose"
      :width="$isMobile ? '90%':'500px'"
    >
      <div class="content">
        <!-- <div v-if="goodsInfo.order_amt">
          <span>Giá sản phẩm：</span>
          <span>
            <span class="num_color">{{ Number(goodsInfo.order_amt) }}</span>
            VND
          </span>
        </div> -->
        <div v-if="goodsInfo.give_coin">
          <span>Kênh hoàn：</span>
          <span>
            <span class="num_color">+{{ Number(goodsInfo.give_coin) }}</span>
            VND
          </span>
        </div>
        <div v-if="goodsInfo.level">
          <span>VIP hoàn：</span>
          <span>
            <span class="num_color">
              +{{ Number(goodsInfo.extra_coin_num) }}
            </span>
            VND
          </span>
        </div>
        <div v-if="goodsInfo.coupon_give_coin">
          <span>Voucher hoàn：</span>
          <span>
            <span class="num_color">
              {{'+' + Number(goodsInfo.coupon_give_coin) }}
            </span>
            VND
          </span>
        </div>
         <!-- <div v-if="goodsInfo.coupon_reduce_amt">
          <span>Voucher giảm：</span>
          <span>
            <span class="num_color">
              {{'-' + Number(goodsInfo.coupon_reduce_amt) }}
            </span>
            VND
          </span>
        </div> -->
        <br />
        <div>
          <span>Tổng số tiền hoàn：</span>
          <span>
            <span class="num_color">
              {{ Number(goodsInfo.total_give) }}
            </span>
            VND
          </span>
        </div>
        <!-- <div>
          <span>Số tiền hoàn thực tế：</span>
          <span>
            <span class="num_color">
              {{
                Number(goodsInfo.coupon_discount_amount) ||
                Number(goodsInfo.order_amt)
              }}
            </span>
            VND
          </span>
        </div> -->
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm"> Nạp thẻ </el-button>
      </div>
    </el-dialog>
    <!-- <div class="prdDescription">
                  <div class="description">
                    <span>giá bán</span>
                    <span v-if="goodsInfo.name === 'coin'">{{
                      Number(goodsInfo.order_amt_platform)
                    }}</span>
                    <span
                      v-else-if="goodsInfo.coupon_type === '3'"
                      class="description_reduce"
                      ><span>{{ Number(goodsInfo.order_amt) }}</span
                      >{{ Number(goodsInfo.coupon_discount_amount) }}</span
                    >
                    <span v-else class="description_reduce">{{
                      Number(goodsInfo.order_amt)
                    }}</span>
                  </div>
                  <div class="description" v-if="goodsInfo.name !== 'coin'">
                    <span>Tặng thêm</span>
                    <span>{{ Number(goodsInfo.total_give) }}</span>
                  </div>
                  <div
                    class="_popProduct_give_detail"
                    v-if="goodsInfo.name !== 'coin'"
                  >
                    <div
                      class="_popProduct_give_detail_cell description"
                      v-if="goodsInfo.give_coin"
                    >
                      <span>{{ goodsInfo.name }}</span>
                      <span>+{{ goodsInfo.give_coin }}</span>
                    </div>

                    <div
                      class="_popProduct_give_detail_cell description"
                      v-if="goodsInfo.level"
                    >
                      <span>VIP {{ goodsInfo.level }} hoàn trả </span>
                      <span>+{{ goodsInfo.extra_coin_num }}</span>
                    </div>

                    <div
                      class="_popProduct_give_detail_cell description"
                      v-if="goodsInfo.coupon_give_coin"
                    >
                      <span>{{ goodsInfo.coupon_title }}</span>
                      <span>+{{ goodsInfo.coupon_give_coin }}</span>
                    </div>
                  </div>
                </div> -->
  </div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      dialogFormVisible: false,
      goodsInfo: {},
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    confirm() {
      this.dialogFormVisible = false;
    },

    handleClose() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-dialog {
  border-radius: 32px !important;
}
.content {
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    span {
      flex: 1;
      text-align: left;
      .num_color {
        color: red;
        font-weight: bold;
      }
      &:last-child {
        text-align: right;
      }
    }
  }
}
/deep/.el-dialog__wrapper {
  text-align: center;
  white-space: nowrap;
  overflow: auto;
  &:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
  .el-dialog {
    margin: 30px auto !important;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    white-space: normal;
  }
}

@media screen and (max-width: 1000px) {
  /deep/.el-dialog {
    width: 90%;
  }
}
</style>
